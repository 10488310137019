<template>
  <div id="page-user-list">

    <div class="flex flex-wrap items-center">


      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">


          <vx-card title="เลือกวันเวลา">
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2" >
           <label class="vs-input--label">เลือกเดือนที่ต้องการดูรายการย้อนหลัง</label>
            <!-- <v-select  v-model="month" :options="monthOptions" name="status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
        <vs-select  class="w-full mt-4" v-model="month" >
                <vs-select-item  :key="index" :value="item.value" :text="item.text" v-for="(item,index) in monthOptions" />
        </vs-select>
            <br>
          </div>
           <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2" >
            <label class="vs-input--label">เลือกปีที่ต้องการดูรายการย้อนหลัง</label>
            <!-- <v-select  v-model="year" :options="yearOptions" name="status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
           <vs-select  class="w-full mt-4" v-model="year" >
                <vs-select-item  :key="index" :value="item.value" :text="item.text" v-for="(item,index) in yearOptions" />
        </vs-select>
            <br>
           </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" >
            <label class="vs-input--label">เวลาเริ่มต้น กะเช้า</label>
            <!-- <v-select  v-model="year" :options="yearOptions" name="status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
           <vs-select  class="w-full mt-4" v-model="hourMoning.start" >
                <vs-select-item  :key="index" :value="item.value" :text="item.text" v-for="(item,index) in hour" />
        </vs-select>
            <br>
           </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" >
            <label class="vs-input--label">เวลาสิ้นสุด กะเช้า</label>
            <!-- <v-select  v-model="year" :options="yearOptions" name="status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
           <vs-select  class="w-full mt-4" v-model="hourMoning.end" >
                <vs-select-item  :key="index" :value="item.value" :text="item.text" v-for="(item,index) in hour" />
        </vs-select>
            <br>
           </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" >
            <label class="vs-input--label">เวลาเริ่มต้น กะดึก</label>
            <!-- <v-select  v-model="year" :options="yearOptions" name="status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
           <vs-select  class="w-full mt-4" v-model="hourNight.start" >
                <vs-select-item  :key="index" :value="item.value" :text="item.text" v-for="(item,index) in hour" />
        </vs-select>
            <br>
           </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" >
            <label class="vs-input--label">เวลาสิ้นสุด กะดึก</label>
            <!-- <v-select  v-model="year" :options="yearOptions" name="status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
           <vs-select  class="w-full mt-4" v-model="hourNight.end" >
                <vs-select-item  :key="index" :value="item.value" :text="item.text" v-for="(item,index) in hour" />
        </vs-select>
            <br>
           </div>
        
           
           <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" >
           <br>
           </div>
        </div>
         <vs-button  color="primary" @click="selectdate_time ()">ค้นหา</vs-button>
          </vx-card>
        <br>

        <div class="vx-row">

          <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
            <statistics-card-line hideChart class="mb-base" icon="PlusCircleIcon" icon-right :statistic="currency(sum_deposit)"
              statisticTitle="ยอดฝาก" color="warning" />
          </div>

          <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
            <statistics-card-line hideChart class="mb-base" icon="MinusCircleIcon" icon-right :statistic="currency(sum_withdraw)"
              statisticTitle="ยอดถอน" color="danger" />
          </div>
           <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="currency(sum_profit)"
              statisticTitle="กำไร" color="success" />
          </div>
        </div>
    
        <vx-card title="ตารางสรุปยอดรายเดือน" v-if="table_data_all.length!==0">
          <h6 class="text-danger mb-4"> * ยอดอัพเดททุก 4 ชั่วโมง (อัพเดทล่าสุด : {{ lastupdate }})</h6>
          <div class="export-table">
            <vs-table  stripe :data="table_data_all">

              <template slot="thead">

                <vs-th>วันที่</vs-th>
                <vs-th> Deposit</vs-th>
                <vs-th> Withdraw </vs-th>
                <vs-th> Profit </vs-th>

              </template>

              <template slot-scope="{data}">

                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td>{{  data[indextr]['date'] }}</vs-td>
                  <vs-td>{{  currency(data[indextr]['sumdeposit']) }}</vs-td>
                  <vs-td>{{  currency(data[indextr]['sumwithdraw']) }}</vs-td>
                  <vs-td class="text-success" v-if="data[indextr]['sumprofit']>0">{{ currency(data[indextr]['sumprofit']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{ currency(data[indextr]['sumprofit']) }}</vs-td>
                </vs-tr>

              </template>

            </vs-table>

          </div>
        </vx-card>
       
      </div>
    </div>

  </div>

</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import vSelect from 'vue-select'
import moment from 'moment'
export default {
  components: {
    StatisticsCardLine,
    vSelect
  },
  data () {
    return {
      popupgame:false,
      monthOptions: [
        {
          text: 'มกราคม',
          value: '01'
        },
        {
          text: 'กุมภาพันธ์',
          value: '02'
        },
        {
          text: 'มีนาคม',
          value: '03'
        },
        {
          text: 'เมษายน',
          value: '04'
        },
        {
          text: 'พฤษภาคม',
          value: '05'
        },
        {
          text: 'มิถุนายน',
          value: '06'
        },
        {
          text: 'กรกฎาคม',
          value: '07'
        },
        {
          text: 'สิงหาคม',
          value: '08'
        },
        {
          text: 'กันยายน',
          value: '09'
        },
        {
          text: 'ตุลาคม',
          value: '10'
        },
        {
          text: 'พฤศจิกายน',
          value: '11'
        },
        {
          text: 'ธันวาคม',
          value: '12'
        }
      ],
      hour: [
        {
          text : '00:00:00',
          value : '0'
        },
        {
          text : '01:00:00',
          value : '1'
        },
        {
          text : '02:00:00',
          value : '2'
        },
        {
          text : '03:00:00',
          value : '3'
        },
        {
          text : '04:00:00',
          value : '4'
        },
        {
          text : '05:00:00',
          value : '5'
        },
        {
          text : '06:00:00',
          value : '6'
        }, {
          text : '07:00:00',
          value : '7'
        }, 
        {
          text : '08:00:00',
          value : '8'
        },
        {
          text : '09:00:00',
          value : '9'
        },
        {
          text : '10:00:00',
          value : '10'
        }, {
          text : '11:00:00',
          value : '11'
        },
        {
          text : '12:00:00',
          value : '12'
        },
        {
          text : '13:00:00',
          value : '13'
        }, 
        {
          text : '14:00:00',
          value : '14'
        },
        {
          text : '15:00:00',
          value : '15'
        },
        {
          text : '16:00:00',
          value : '16'
        },
        {
          text : '17:00:00',
          value : '17'
        },
        {
          text : '18:00:00',
          value : '18'
        },
        {
          text : '19:00:00',
          value : '19'
        },
        {
          text : '20:00:00',
          value : '20'
        },
        {
          text : '21:00:00',
          value : '21'
        },
        {
          text : '22:00:00',
          value : '22'
        },
        {
          text : '23:00:00',
          value : '23'
        }
      ],
      hourMoning : {
        start : '6',
        end : '18'
      },
      hourNight : {
        start : '19',
        end : '0'
      },
      yearOptions: [
        {
          text: moment.tz('Asia/Bangkok').subtract(1, 'year').format('YYYY').toString(),
          value: moment.tz('Asia/Bangkok').subtract(1, 'year').format('YYYY')
        },
        {
          text: moment.tz('Asia/Bangkok').format('YYYY'),
          value: moment.tz('Asia/Bangkok').format('YYYY')
        }
      ],
      month: moment.tz('Asia/Bangkok').format('MM'),
      year: moment.tz('Asia/Bangkok').format('YYYY'),
      table_data_all: [],
      table_data: [],
      sum_data: [],
      sum_deposit: 0,
      sum_withdraw: 0,
      sumsagame: 0,
      sumpgslot: 0,
      sumjokerslot: 0,
      sumsbobet:0,
      sumgssslot:0,
      sumsppslot:0,
      sumppslot:0,
      sumsexy: 0,
      sum_profit: 0,
    
      sumuggame:0,
      lastupdate: moment.tz('Asia/Bangkok').format('YYYY-MM-DD H:mm:ss'),
      index:0
    }
  },
  async mounted () {
    await this.selectdate_time()
  },
  methods: {
    async  selectdate_time () {
    
      await axios
        .get(`dashboardmonth/selectmonth/${this.month}/${this.year}`, {
        })
        .then(response => (this.table_data = response.data))
      if (this.table_data.length === 0) {
         
        this.sum_deposit = '0'
        this.sum_withdraw = '0'
        this.sum_profit = '0'
       
      } else {
     
        this.sum_deposit = this.table_data[0].sm_sum_deposit
        this.sum_withdraw = this.table_data[0].sm_sum_withdraw
        this.sum_profit = this.table_data[0].sm_sum_profit
    
      }

    },
    currency (amount) {
      amount = (amount * 1.00).toFixed(2)
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(amount)
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        // amount = String(Math.abs(amount))
        // return `-${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }

    }
  }
}

</script>
